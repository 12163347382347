svg {
    width: 100%;
}

.wave {
    animation: wave 3s linear;
    animation-iteration-count: infinite;
    fill: #563d9c;
}

.gooeff {
    filter: url(#goo);
}

#wave2 {
    animation-duration: 5s;
    animation-direction: reverse;
    opacity: 0.25;
}

#wave3 {
    animation-duration: 7s;
    opacity: 0.2;
}

@keyframes wave {
    to {
        transform: translateX(-100%);
    }
}
