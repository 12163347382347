@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    color-scheme: dark;
}

// This is to a fix a known issue with react-vertical-timeline on mobile devices where the slidein animation
// that initiates when visible, causes the NavBar to temporarily disappear and reappear, causing a jenky scroll
//
// See https://github.com/stephane-monnot/react-vertical-timeline/issues/24 (note I didn't have to modify the
// component's CSS file as suggested as another fix)
html,
body {
    overflow-x: hidden;
}

.hash-span {
    margin-top: -100px;
    padding-bottom: 100px;
    display: block;
}

.black-gradient {
    background: #000000; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #434343,
        #000000
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #434343,
        #000000
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.violet-gradient {
    background: #804dee;
    background: linear-gradient(-90deg, #804dee 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(
        -90deg,
        #804dee 0%,
        rgba(60, 51, 80, 0) 100%
    );
}

.green-pink-gradient {
    background: "#00cea8";
    background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
    background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
}

.orange-text-gradient {
    background: #f12711; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #f12711,
        #f5af19
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to top,
        #f12711,
        #f5af19
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.green-text-gradient {
    background: #11998e; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #11998e,
        #38ef7d
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to top,
        #11998e,
        #38ef7d
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blue-text-gradient {
    /* background: -webkit-linear-gradient(#eee, #333); */
    background: #56ccf2; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #2f80ed,
        #56ccf2
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to top,
        #2f80ed,
        #56ccf2
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
    background: #ec008c; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #ec008c,
        #fc6767
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to top,
        #ec008c,
        #fc6767
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
